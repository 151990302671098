import React, { Component } from "react";
import { MDBCol, MDBRow, MDBTable, MDBTableBody } from "mdbreact";
import { FaEdit, FaFilter, FaSave, FaSignOutAlt } from "react-icons/fa";
import { FaMessage, FaRegCircleUser } from "react-icons/fa6";
import { PiCirclesFourLight, PiChatTeardropTextLight } from "react-icons/pi";
import { GiDoorRingHandle } from "react-icons/gi";
import { MdHeight, MdMonitorWeight } from "react-icons/md";
import Select from "react-select";
import { BrowserView, MobileView } from "react-device-detect";
import {
  MDBCollapse,
  MDBIcon,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBNavbarNav,
  MDBNavbarToggler,
} from "mdb-react-ui-kit";
import { toast, ToastContainer } from "react-toastify";
import IntroPage from "./IntroPage";
import RightSideNavbar from "./rightSideNavbar";
import { LanguageContext } from "../LanguageContext";
import { withTranslation } from "react-i18next";
import { useParams } from "react-router";
import { connect } from "react-redux";
import CheckboxOption from "./CheckboxOption";
import { Chip, Icon } from "@mui/material";
import { IoLanguageOutline } from "react-icons/io5";
import { RiHomeLine } from "react-icons/ri";


const Box = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: "1px solid #ccc",
  borderRadius: "8px",
  height: "38px",
  fontSize: "18px",
  textAlign: "center",
  fontWeight: "bold",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  backgroundColor: "white",
};
const BoxMobile = {
  display: "flex",
  alignItems: "center",
  maxwidth: "100%",
  justifyContent: "center",
  border: "1px solid #ccc",
  borderRadius: "8px",
  height: "32px",
  fontSize: "15px",
  textAlign: "center",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  backgroundColor: "white",
  paddingRight: "10px",
};
class UserProfile extends Component {
  static contextType = LanguageContext;

  constructor(props) {
    super(props);

    this.state = {
      open: false,
      profile: null,
      isIntro: localStorage.getItem("intro"),
      SelectedFile: null,
      lang: this.props.params.lang,
      isFaithEnable: true,
    };

    this.gender_lst = [
      { value: 0, label: "زن" },
      { value: 1, label: "مرد" },
    ];

    this.gender_lst_en = [
      { value: 0, label: "Female" },
      { value: 1, label: "Male" },
    ];

    this.religious_lst = [
      { value: 1, label: "اسلام" },
      { value: 2, label: "مسیحی" },
    ];

    this.marital_status_lst = [
      { value: 1, label: "مجرد" },
      { value: 2, label: "بیوه" },
      { value: 3, label: "مطلقه" },
    ];

    this.language_lst = [
      { value: 1, label: "فارسی" },
      { value: 2, label: "انگلیسی" },
    ];

    this.religious_lst_en = [
      { value: 1, label: "Islam" },
      { value: 2, label: "Christian" },
    ];

    this.marital_status_lst_en = [
      { value: 1, label: "Single" },
      { value: 2, label: "Widow" },
      { value: 3, label: "Divorced" },
    ];

    this.language_lst_en = [
      { value: 1, label: "Persian/Farsi" },
      { value: 2, label: "English" },
    ];

    this.filter_lst_en = [
      { value: "height", label: "Height" },
      { value: "weight", label: "Weight" },
      { value: "Level_of_Education", label: "Level of Education" },
      { value: "Field_of_Study", label: "Field of Study" },
      { value: "Occupation", label: "Occupation" },
      { value: "City_you_live", label: "City you live" },
      { value: "Marital_Status", label: "Marital Status" },
      { value: "Number_of_Children", label: "Number of Children" },
      { value: "Languages", label: "Languages" },
      { value: "Religious", label: "Religious" },
      { value: "religious_type", label: "Faith" },
      { value: "nationality", label: "Nationality" },
      { value: "age", label: "Age" },
    ];

    this.filter_lst_fa = [
      { value: "height", label: "قد" },
      { value: "weight", label: "وزن" },
      { value: "Level_of_Education", label: "سطح تحصیلات" },
      { value: "Field_of_Study", label: "رشته تحصیلی" },
      { value: "Occupation", label: "شغل" },
      { value: "City_you_live", label: "شهر محل اقامت" },
      { value: "Marital_Status", label: "وضعیت تأهل" },
      { value: "Number_of_Children", label: "تعداد فرزندان" },
      { value: "Languages", label: "زبان‌ها" },
      { value: "Religious", label: "مذهبی" },
      { value: "religious_type", label: "دین" },
      { value: "nationality", label: "تابعیت" },
      { value: "age", label: "سن" },
    ];

    this.religious_type_lst = [
      { value: 1, label: "شیعه" },
      { value: 2, label: "سنی" },
    ];

    this.religious_type_lst_en = [
      { value: 1, label: "Shia" },
      { value: 2, label: "Sunni" },
    ];

    this.degree_lst = [
      { value: 0, label: "بدون مدرک" },
      { value: 1, label: "سیکل" },
      { value: 2, label: "دیپلم" },
      { value: 3, label: "کاردانی" },
      { value: 4, label: "لیسانس" },
      { value: 5, label: "فوق لیسانس" },
      { value: 6, label: "دکترا" },
      { value: 7, label: "فوق دکترا" },
    ];

    this.degree_lst_en = [
      { value: 0, label: "Other" },
      { value: 1, label: "High School" },
      { value: 2, label: "diploma" },
      { value: 3, label: "Associate degree" },
      { value: 4, label: "Bachelor's degree" },
      { value: 5, label: "Master's degree" },
      { value: 6, label: "Ph.D." },
      { value: 7, label: "PostDoc" },
    ];

    this.languages_lst_en = [
      { value: 0, label: "Persian/Farsi" },
      { value: 1, label: "English" },
      { value: 2, label: "Spanish" },
      { value: 3, label: "French" },
      { value: 4, label: "German" },
      { value: 5, label: "Chinese (Simplified)" },
      { value: 6, label: "Arabic" },
      { value: 7, label: "Russian" },
      { value: 8, label: "Japanese" },
      { value: 9, label: "Portuguese" },
      { value: 10, label: "Italian" },
      { value: 11, label: "Dutch" },
    ];
    this.nationality_tpl = [
      { value: 0, label: "ایران" },
      { value: 1, label: "افغانستان" },
      { value: 2, label: "آذربایجان" },
      { value: 3, label: "پاکستان" },
      { value: 4, label: "انگلستان" },

    ]

    this.nationality_tpl_en = [
      { value: 0, label: "Iran" },
      { value: 1, label: "Afghanistan" },
      { value: 2, label: "Azerbaijan" },
      { value: 3, label: "Pakistan" },
      { value: 4, label: "United Kingdom" },
    ]

    this.languages_lst_fa = [
      { value: 0, label: "فارسی" },
      { value: 1, label: "انگلیسی" },
      { value: 2, label: "اسپانیایی" },
      { value: 3, label: "فرانسوی" },
      { value: 4, label: "آلمانی" },
      { value: 5, label: "چینی" },
      { value: 6, label: "عربی" },
      { value: 7, label: "روسی" },
      { value: 8, label: "ژاپنی" },
      { value: 9, label: "پرتغالی" },
      { value: 10, label: "ایتالیایی" },
      { value: 11, label: "هلندی" },
    ];
  }

  chooseLang() {

    if (this.state.lang === "fa") {
      localStorage.setItem("lang", this.state.lang);
    } else {
      if (this.state.lang === "en") {
        localStorage.setItem("lang", this.state.lang);
      } else {
        if (localStorage.getItem("lang") === null) {
          localStorage.setItem("lang", "en");
        }
      }
    }

    var l = localStorage.getItem("lang");
    this.languageChangeListener = () => {
      var lang = localStorage.getItem("lang");
    };

    this.props.i18n.on("languageChanged", this.languageChangeListener);

    if (l !== null) {
      const { setLanguage } = this.context;
      setLanguage(l);

      this.props.i18n.changeLanguage(l);
    }
  }

  componentDidMount() {
    this.chooseLang();

    this.getUserProfile();
  }

  getUserProfile() {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    fetch("/api/v1/profile/", requestOptions)
      .then((response) => {
        if (!response.ok) throw response.status;
        else return response.json();
      })
      .then((response) => {
        if (response.status === 200) {

          localStorage.setItem(
            "profile",
            JSON.stringify({
              is_registration_complete: response.data.is_registration_complete,
              is_seen_videos: response.data.is_seen_videos,
              is_verified: response.data.is_verified,
            })
          );

          if (response.data.religious === 1) {
            this.setState({
              isFaithEnable: false,
              profile: response.data,
            });
          } else {
            this.setState({
              profile: response.data,
              isFaithEnable: true,
            });
          }
        }
      })
      .catch((error) => { });
  }

  mobileView() {
    const { setLanguage } = this.context;

    const changeLangBTN = (newLanguage) => {
      localStorage.setItem("lang", newLanguage);
      setLanguage(newLanguage);
      window.location.reload();
    };

    if (this.props.i18n.language == "fa") {
      if (this.state.isIntro == null || this.state.isIntro == false) {
        return (
          <body
            className="text-right bg-body"
            dir="rtl"
            style={{ height: "100vh" }}
          >
            <div className={"container-fluid"}>
              <MDBNavbar
                expand="lg"
                light
                bgColor="light"
                className={"p-3"}
                style={{ direction: "ltr" }}
              >
                <MDBNavbarBrand href="/">
                  <img
                    src="/logo192.png"
                    height="30"
                    alt="کانون هدایت"
                    loading="lazy"
                  />
                </MDBNavbarBrand>

                <MDBNavbarToggler
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  onClick={() => this.setState({ open: !this.state.open })}
                >
                  <MDBIcon icon="bars" fas />
                </MDBNavbarToggler>

                <MDBCollapse navbar show={this.state.open}>
                  <MDBNavbarNav className="mr-auto mb-2 mb-lg-0 justify-content-end">
                    <MDBNavbarItem>
                      <MDBNavbarLink
                        active
                        aria-current="page"
                        href="/my-profile"
                        style={{ fontWeight: "bold" }}
                      >
                        {this.props.i18n.t("my_profile")}
                      </MDBNavbarLink>
                    </MDBNavbarItem>
                    <MDBNavbarItem>
                      <MDBNavbarLink
                        href="/learning"
                        style={{ fontWeight: "bold" }}
                      >
                        {this.props.i18n.t('plans')}
                      </MDBNavbarLink>
                    </MDBNavbarItem>

                    <MDBNavbarItem>
                      <MDBNavbarLink
                        href="/users"
                        style={{ fontWeight: "bold" }}
                      >
                        {this.props.i18n.t('encounters')}
                      </MDBNavbarLink>
                    </MDBNavbarItem>

                    <MDBNavbarItem>
                      <MDBNavbarLink href="/" style={{ fontWeight: "bold" }}>
                        {this.props.i18n.t("home_page_short")}
                      </MDBNavbarLink>
                    </MDBNavbarItem>

                    <MDBNavbarItem>
                      <MDBNavbarLink
                        href="/logout"
                        style={{ fontWeight: "bold" }}
                      >
                        {this.props.i18n.t("sign_out")}
                      </MDBNavbarLink>
                    </MDBNavbarItem>

                    <div className={"mt-3"}>
                      <MDBIcon
                        flag="uk"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => changeLangBTN("en")}
                      />
                      <MDBIcon
                        flag="ir"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => changeLangBTN("fa")}
                      />
                    </div>
                  </MDBNavbarNav>
                </MDBCollapse>
              </MDBNavbar>

              <IntroPage />
            </div>
          </body>
        );
      }
      return (
        <body className="text-right bg-body" dir="rtl">
          <MDBNavbar
            expand="lg"
            light
            bgColor="light"
            className={"p-3 mb-3"}
            style={{ direction: "ltr" }}
          >
            <MDBNavbarBrand href="/">
              <img
                src="/logo192.png"
                height="30"
                alt="کانون هدایت"
                loading="lazy"
              />
            </MDBNavbarBrand>

            <MDBNavbarToggler
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => this.setState({ open: !this.state.open })}
            >
              <MDBIcon icon="bars" fas />
            </MDBNavbarToggler>

            <MDBCollapse navbar show={this.state.open}>
              <MDBNavbarNav className="mr-auto mb-2 mb-lg-0 justify-content-end">
                <MDBNavbarItem>
                  <MDBNavbarLink
                    active
                    aria-current="page"
                    href="/my-profile"
                    style={{ fontWeight: "bold" }}
                  >
                    {this.props.i18n.t("my_profile")} <FaRegCircleUser />
                  </MDBNavbarLink>
                </MDBNavbarItem>

                <MDBNavbarItem>
                  <MDBNavbarLink
                    href="/learning"
                    style={{ fontWeight: "bold" }}
                  >
                    {this.props.i18n.t('plans')}
                    <RiHomeLine/>
                  </MDBNavbarLink>
                </MDBNavbarItem>


                {/* <MDBNavbarItem>
                                <MDBNavbarLink href='/learning' style={{fontWeight: 'bold'}}>{this.props.i18n.t('learning')} </MDBNavbarLink>
                            </MDBNavbarItem> */}

                <MDBNavbarItem>
                  <MDBNavbarLink href="/users" style={{ fontWeight: "bold" }}>
                    {this.props.i18n.t('encounters')}
                    <PiCirclesFourLight />
                  </MDBNavbarLink>
                </MDBNavbarItem>

                <MDBNavbarItem>
                  <MDBNavbarLink
                    href="/chatroom"
                    style={{ fontWeight: "bold" }}
                  >
                    {this.props.i18n.t("chatroom")}
                    <PiChatTeardropTextLight />
                  </MDBNavbarLink>
                </MDBNavbarItem>

                <MDBNavbarItem>
                  <MDBNavbarLink href="/" style={{ fontWeight: "bold" }}>
                    {this.props.i18n.t("home_page_short")}
                  </MDBNavbarLink>
                </MDBNavbarItem>
                <MDBNavbarItem>
                  <MDBNavbarLink href="/logout" style={{ fontWeight: "bold" }}>
                    {this.props.i18n.t("sign_out")}
                    <FaSignOutAlt />
                  </MDBNavbarLink>
                </MDBNavbarItem>

                <div className={"mt-3"}>
                  <MDBIcon
                    flag="uk"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => changeLangBTN("en")}
                  />
                  <MDBIcon
                    flag="ir"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => changeLangBTN("fa")}
                  />
                </div>
              </MDBNavbarNav>
            </MDBCollapse>
          </MDBNavbar>

          {/* <MDBRow className={"m-4"}>
            <div className={"mt-0 text-left"}>
              <button
                className="btn"
                style={{ background: "#FF686B", color: "#fff" }}
                onClick={(v) => this.chatBTN(v)}
              >
                <label>
                  <FaMessage /> {this.props.i18n.t("conversion_btn_title")}
                </label>
              </button>
            </div>
          </MDBRow>

          <hr style={{ color: "black" }} /> */}
          {/* profile picture section */}
          <MDBRow className="" style={{ marginTop: '50px', marginBottom: "45px", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div className="text-center" style={{ position: "relative", display: "inline-block" }}>
              <div style={{ position: "relative", display: "inline-block" }}>
                <img
                  height="270px"
                  style={{
                    borderRadius: "5%",
                  }}
                  src={this.state.profile.picture}
                  alt="Profile"
                />

                {/* Display the verified badge if verified is true */}
                {this.state.profile.is_verified && (
                  <img
                    src="/verified.png" // Path to the image in the public folder
                    alt="Verified"
                    style={{
                      position: "absolute",
                      top: "-18px", // Adjust to place the badge at the top
                      right: "-18px", // Adjust to place the badge at the right corner
                      width: "45px",
                      height: "45px",
                      borderRadius: "50%",
                    }}
                  />
                )}
              </div>

              <div style={{ marginTop: "25px", direction: "rtl", fontSize: "1.3em", fontWeight: "bold" }}>
                {this.state.profile.username} / 29{this.state.profile.birthday}
              </div>
            </div>
          </MDBRow>


          <MDBRow className={"bg-white p-0 m-0 mb-5 mt-3"}>
            {/* <div style={{ background: "#fff" }}>
              <label style={{ fontWeight: "bold" }}>
                {this.props.i18n.t("about_me")}
              </label>
              <textarea
                rows={8}
                name="about_me"
                className="form-control text-left ltr"
                onChange={(v) => this.about_me_Value(v)}
              >
                {this.state.profile.about_me}
              </textarea>
            </div>

            <div style={{ background: "#fff" }}>
              <label
                className={"m-2 mt-4"}
                style={{ fontWeight: "bold", fontSize: "16px" }}
              >
                {this.props.i18n.t("My_info")}
              </label>
            </div> */}
            <MDBRow>
              <MDBCol>
                <label style={{ fontWeight: "bold" }}>
                  {this.props.i18n.t("Bio")}
                </label>
                <div> {this.state.profile.about_me} </div>
              </MDBCol>
            </MDBRow>
            <div>
              <label
                className={"m-1 mt-5"}
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                {this.props.i18n.t("nationally_and_region")}
              </label>
            </div>
            <MDBRow className={"mt-2"}>
              <MDBCol
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <Chip
                  style={{ ...BoxMobile }}
                  avatar={
                    <MDBIcon flag={this.state.profile.nationality.flag} className={'m-2'} />
                  }

                  label={
                    this.nationality_tpl.find(
                      (nationality) =>
                        nationality.value === this.state.profile.nationality?.name
                    )?.label || "---" // Correct fallback to "---"
                  }
                  variant="outlined"
                  size="medium"
                  className={"m-1"}
                />
                <Chip
                  style={{ ...BoxMobile }}
                  avatar={
                    <MDBIcon
                      fas
                      icon=""
                      className={"mt-2"}
                      style={{ fontSize: "15px", marginLeft: "14px" }}
                    />
                  }
                  label={
                    this.religious_lst.find(
                      (religious) =>
                        religious.value === this.state.profile.religious
                    )?.label || "---"}
                  variant="outlined"
                  size="medium"
                  className={"m-1"}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>
                {this.state.profile.religious_type != 0 && (
                  <Chip
                    style={{ ...BoxMobile }}
                    avatar={
                      <MDBIcon
                        fas
                        icon=""
                        className={"mt-2"}
                        style={{ fontSize: "15px", marginLeft: "14px" }}
                      />
                    }
                    label={
                      this.religious_type_lst.find(
                        (religious) =>
                          religious.value === this.state.profile.religious_type
                      )?.label}
                    variant="outlined"
                    size="medium"
                    className={"m-1"}
                  />
                )}
              </MDBCol>
              <MDBCol></MDBCol>
            </MDBRow>

            {/* education_and_career */}
            <div>
              <label
                className={"m-1 mt-5"}
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                {this.props.i18n.t("education_and_career")}
              </label>
            </div>
            <MDBRow className={"mt-3"}>
              <MDBCol
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <Chip
                  style={{ ...BoxMobile }}
                  avatar={
                    <MDBIcon
                      fas
                      icon="user-graduate"
                      className={"mt-2"}
                      style={{ fontSize: "15px", marginLeft: "2px" }}
                    />
                  }
                  label={
                    this.degree_lst.find(
                      (degree) =>
                        degree.value === this.state.profile.degree
                    )?.label || "---"
                  }
                  variant="outlined"
                  size="medium"
                  className={"m-1"}
                />

                <Chip
                  style={{ ...BoxMobile }}
                  avatar={
                    <MDBIcon
                      fas
                      icon="book-open"
                      className={"mt-2"}
                      style={{ fontSize: "15px", marginLeft: "14px" }}
                    />
                  }
                  label={this.state.profile.major || "---"}
                  variant="outlined"
                  size="medium"
                  className={"m-1"}
                />
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol>

                <Chip
                  style={{ ...BoxMobile }}
                  avatar={
                    <MDBIcon
                      fas
                      icon="briefcase"
                      className={"mt-2"}
                      style={{ fontSize: "15px", marginLeft: "14px" }}
                    />
                  }
                  label={this.state.profile.occupation || "---"}
                  variant="outlined"
                  size="medium"
                  className={"m-1"}
                />

              </MDBCol>


              <MDBCol></MDBCol>
            </MDBRow>



            {/* about me section */}

            <div>
              <label
                className={"m-1 mt-5"}
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                {this.props.i18n.t("about_me")}
              </label>
            </div>


            <MDBRow className={"mt-3"}>
              <MDBCol
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <Chip
                  style={{ ...BoxMobile }}
                  avatar={
                    <MDBIcon
                      fas
                      icon="map-marker"
                      className={"mt-2"}
                      style={{ fontSize: "15px", marginLeft: "14px" }}
                    />
                  }
                  label={this.state.profile.country_of_live || "---"}
                  variant="outlined"
                  size="medium"
                  className={"m-1"}
                />

                <Chip
                  style={{ ...BoxMobile }}
                  avatar={
                    <GiDoorRingHandle
                      style={{ fontSize: "15px", marginRight: "10px" }}
                    />
                  }
                  label={
                    this.marital_status_lst.find(
                      (martial_status) =>
                        martial_status.value ===
                        this.state.profile.martial_status
                    )?.label || "---"
                  }
                  variant="outlined"
                  size="medium"
                  className={"m-1"}
                />
              </MDBCol>
            </MDBRow>


            {this.state.profile.languages && this.state.profile.languages.length > 0 && (
              <MDBRow className="mt-3">
                <MDBCol
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    flexWrap: "wrap"
                  }}
                >
                  {this.state.profile.languages.map((language, index) => (
                    <Chip
                      key={index}
                      style={{
                        width: "calc(41%- 5px)", // Ensure two columns with spacing
                        ...BoxMobile,
                      }}
                      avatar={
                        <IoLanguageOutline
                          style={{ fontSize: "15px", marginRight: "10px" }}
                        />
                      }
                      label={
                        this.languages_lst_fa.find(
                          (langu) =>
                            langu.value === language.value
                        )?.label || "---"

                      }
                      // label={`${language.label}`}
                      variant="outlined"
                      size="medium"
                      className="m-1"
                    />
                  ))}
                </MDBCol>
              </MDBRow>
            )}

            <MDBRow className={"mt-3"}>
              <MDBCol
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <Chip
                  style={{ ...BoxMobile }}
                  avatar={
                    <MdHeight
                      style={{ fontSize: "15px", marginRight: "10px" }}
                    />
                  }
                  label={
                    this.state.profile.height || "---"
                  }
                  variant="outlined"
                  size="medium"
                  className={"m-1"}
                />

                <Chip
                  style={{ ...BoxMobile }}
                  avatar={
                    <MdMonitorWeight
                      style={{ fontSize: "15px", marginRight: "10px" }}
                    />
                  }
                  label={this.state.profile.weight || "---"}
                  variant="outlined"
                  size="medium"
                  className={"m-1"}
                />
              </MDBCol>
            </MDBRow>



            {/* Interest Section */}
            <div>
              <label
                className={"m-1 mt-5"}
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                {this.props.i18n.t("Interest")}
              </label>
            </div>

            <MDBRow className={"mt-3"}>
              <MDBCol
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
              </MDBCol>
            </MDBRow>



            {/* Personality section */}
            <div>
              <label
                className={"m-1 mt-5 mb-10"}
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                {this.props.i18n.t("Personality")}
              </label>
            </div>
            <MDBRow className={"mt-3"}>
              <MDBCol
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}>

              </MDBCol>
            </MDBRow>
            <MDBRow className={"mb-5 mt-5"}>
              <MDBCol
                className={
                  "text-center align-items-center justify-content-center d-flex"
                }
              >
                <button
                  className="btn"
                  style={{
                    background: "#3B3B3B",
                    color: "#fff",
                    marginTop: "20px",
                    fontSize: "20px",
                    marginBottom: "50px",
                  }}
                  onClick={(v) => this.EditBTN(v)}
                >
                  <label>{this.props.i18n.t("Edit_profile")}</label>
                </button>
              </MDBCol>
            </MDBRow>
          </MDBRow>




        </body>
      );
    } else {
      if (this.state.isIntro == null || this.state.isIntro == false) {
        return (
          <body
            className="text-right bg-body"
            dir="rtl"
            style={{ height: "100vh" }}
          >
            <div className={"container-fluid"}>
              <MDBNavbar
                expand="lg"
                light
                bgColor="light"
                className={"p-3"}
                style={{ direction: "ltr" }}
              >
                <MDBNavbarBrand href="/">
                  <img
                    src="/logo192.png"
                    height="30"
                    alt="کانون هدایت"
                    loading="lazy"
                  />
                </MDBNavbarBrand>

                <MDBNavbarToggler
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  onClick={() => this.setState({ open: !this.state.open })}
                >
                  <MDBIcon icon="bars" fas />
                </MDBNavbarToggler>

                <MDBCollapse navbar show={this.state.open}>
                  <MDBNavbarNav className="mr-auto mb-2 mb-lg-0 justify-content-end">
                    <MDBNavbarItem>
                      <MDBNavbarLink
                        active
                        aria-current="page"
                        href="/my-profile"
                        style={{ fontWeight: "bold" }}
                      >
                        {this.props.i18n.t("my_profile")}
                      </MDBNavbarLink>
                    </MDBNavbarItem>
                    
                    <MDBNavbarItem>
                      <MDBNavbarLink
                        href="/learning"
                        style={{ fontWeight: "bold" }}
                      >
                        {this.props.i18n.t('plans')}
                      </MDBNavbarLink>
                    </MDBNavbarItem>

                    <MDBNavbarItem>
                      <MDBNavbarLink
                        href="/users"
                        style={{ fontWeight: "bold" }}
                      >
                        {this.props.i18n.t('encounters')}
                      </MDBNavbarLink>
                    </MDBNavbarItem>

                    <MDBNavbarItem>
                      <MDBNavbarLink href="/" style={{ fontWeight: "bold" }}>
                        {this.props.i18n.t("home_page_short")}
                      </MDBNavbarLink>
                    </MDBNavbarItem>

                    <MDBNavbarItem>
                      <MDBNavbarLink
                        href="/logout"
                        style={{ fontWeight: "bold" }}
                      >
                        {this.props.i18n.t("sign_out")}
                      </MDBNavbarLink>
                    </MDBNavbarItem>

                    <div className={"mt-3"}>
                      <MDBIcon
                        flag="uk"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => changeLangBTN("en")}
                      />
                      <MDBIcon
                        flag="ir"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => changeLangBTN("fa")}
                      />
                    </div>
                  </MDBNavbarNav>
                </MDBCollapse>
              </MDBNavbar>

              <IntroPage />
            </div>
          </body>
        );
      }
      return (
        <body className="text-right bg-body" dir="rtl">
          <MDBNavbar
            expand="lg"
            light
            bgColor="light"
            className={"p-3 mb-3 text-left"}
            style={{ direction: "ltr" }}
          >
            <MDBNavbarBrand href="/">
              <img
                src="/logo192.png"
                height="30"
                alt="کانون هدایت"
                loading="lazy"
              />
            </MDBNavbarBrand>

            <MDBNavbarToggler
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => this.setState({ open: !this.state.open })}
            >
              <MDBIcon icon="bars" fas />
            </MDBNavbarToggler>

            <MDBCollapse navbar show={this.state.open}>
              <MDBNavbarNav className="mr-auto mb-2 mb-lg-0 justify-content-end">
                <MDBNavbarItem>
                  <MDBNavbarLink
                    active
                    aria-current="page"
                    href="/my-profile"
                    style={{ fontWeight: "bold" }}
                  >
                    <FaRegCircleUser style={{ marginTop: "-5px", marginRight: "5px" }} />
                    {this.props.i18n.t("my_profile")}
                  </MDBNavbarLink>
                </MDBNavbarItem>

                <MDBNavbarItem>
                  <MDBNavbarLink
                    href="/learning"
                    style={{ fontWeight: "bold" }}
                  >
                    <RiHomeLine style={{ marginTop: "-5px", marginRight: "5px" }}/>
                    {this.props.i18n.t('plans')}
                  </MDBNavbarLink>
                </MDBNavbarItem>



                <MDBNavbarItem>
                  <MDBNavbarLink href="/users" style={{ fontWeight: "bold" }}>
                    <PiCirclesFourLight style={{ marginTop: "-5px", marginRight: "5px" }} />

                    {this.props.i18n.t('encounters')}
                  </MDBNavbarLink>
                </MDBNavbarItem>
                <MDBNavbarItem>
                  <MDBNavbarLink
                    href="/chatroom"
                    style={{ fontWeight: "bold" }}
                  >
                    <PiChatTeardropTextLight style={{ marginTop: "-5px", marginRight: "5px" }} />
                    {this.props.i18n.t("chatroom")}
                  </MDBNavbarLink>
                </MDBNavbarItem>

                <MDBNavbarItem>
                  <MDBNavbarLink href="/" style={{ fontWeight: "bold" }}>
                    {this.props.i18n.t("home_page_short")}
                  </MDBNavbarLink>
                </MDBNavbarItem>

                <MDBNavbarItem>
                  <MDBNavbarLink href="/logout" style={{ fontWeight: "bold" }}>
                    {this.props.i18n.t("sign_out")}
                  </MDBNavbarLink>
                </MDBNavbarItem>

                <div className={"mt-3"}>
                  <MDBIcon
                    flag="uk"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => changeLangBTN("en")}
                  />
                  <MDBIcon
                    flag="ir"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => changeLangBTN("fa")}
                  />
                </div>
              </MDBNavbarNav>
            </MDBCollapse>
          </MDBNavbar>

          <MDBRow className="ltr text-left" style={{ marginTop: '50px', marginBottom: "45px", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div className="text-center" style={{ position: "relative", display: "inline-block" }}>
              <div style={{ position: "relative", display: "inline-block" }}>
                <img
                  height="270px"
                  style={{
                    borderRadius: "5%",
                  }}
                  src={this.state.profile.picture}
                  alt="Profile"
                />

                {/* Display the verified badge if verified is true */}
                {this.state.profile.is_verified && (
                  <img
                    src="/verified.png" // Path to the image in the public folder
                    alt="Verified"
                    style={{
                      position: "absolute",
                      top: "-18px", // Adjust to place the badge at the top
                      right: "-18px", // Adjust to place the badge at the right corner
                      width: "45px",
                      height: "45px",
                      borderRadius: "50%",
                    }}
                  />
                )}
              </div>

              <div style={{ marginTop: "25px", direction: "rtl", fontSize: "1.3em", fontWeight: "bold" }}>
                {this.state.profile.username} / 29{this.state.profile.birthday}
              </div>
            </div>
          </MDBRow>


          <MDBRow className={"bg-white p-0 m-0 mb-5 ltr text-left"}>

            <MDBRow>
              <MDBCol>
                <label style={{ fontWeight: "bold" }}>
                  {this.props.i18n.t("Bio")}
                </label>
                <div> {this.state.profile.about_me} </div>
              </MDBCol>
            </MDBRow>


            <div>
              <label
                className={"m-1 mt-5"}
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                {this.props.i18n.t("nationally_and_region")}
              </label>
            </div>


            <MDBRow className={"mt-2"}>

              <MDBCol
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >

                <Chip
                  style={{ ...BoxMobile }}
                  avatar={
                    <MDBIcon flag={this.state.profile.nationality.flag} className={'m-2'} />
                  }
                  label={
                    this.nationality_tpl_en.find(
                      (nationality) =>
                        nationality.value === this.state.profile.nationality.name
                    )?.label || "---"}
                  variant="outlined"
                  size="medium"
                  className={"m-1"}
                />

                <Chip
                  style={{ ...BoxMobile }}
                  avatar={
                    <MDBIcon
                      fas
                      icon=""
                      className={"mt-2"}
                      style={{ fontSize: "15px", marginLeft: "14px" }}
                    />
                  }
                  label={
                    this.religious_lst_en.find(
                      (religious) =>
                        religious.value === this.state.profile.religious
                    )?.label || "---"}
                  variant="outlined"
                  size="medium"
                  className={"m-1"}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>
                {this.state.profile.religious_type != 0 && (
                  <Chip
                    style={{ ...BoxMobile }}
                    avatar={
                      <MDBIcon
                        fas
                        icon=""
                        className={"mt-2"}
                        style={{ fontSize: "15px", marginLeft: "14px" }}
                      />
                    }
                    label={
                      this.religious_type_lst_en.find(
                        (religious) =>
                          religious.value === this.state.profile.religious_type
                      )?.label}
                    variant="outlined"
                    size="medium"
                    className={"m-1"}
                  />
                )}
              </MDBCol>

            </MDBRow>

            {/* education_and_career */}
            <div>
              <label
                className={"m-1 mt-5"}
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                {this.props.i18n.t("education_and_career")}
              </label>
            </div>

            <MDBRow className={"mt-4"}>
              <MDBCol
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <Chip
                  style={{ ...BoxMobile }}
                  avatar={
                    <MDBIcon
                      fas
                      icon="user-graduate"
                      className={"mt-2"}
                      style={{ fontSize: "15px", marginLeft: "14px" }}
                    />
                  }
                  label={
                    this.degree_lst_en.find(
                      (degree) =>
                        degree.value === this.state.profile.degree
                    )?.label || "---"
                  }
                  variant="outlined"
                  size="medium"
                  className={"m-1"}
                />

                <Chip
                  style={{ ...BoxMobile }}
                  avatar={
                    <MDBIcon
                      fas
                      icon="book-open"
                      className={"mt-2"}
                      style={{ fontSize: "15px", marginLeft: "14px" }}
                    />
                  }
                  label={this.state.profile.major || "---"}
                  variant="outlined"
                  size="medium"
                  className={"m-1"}
                />

              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol>

                <Chip
                  style={{ ...BoxMobile }}
                  avatar={
                    <MDBIcon
                      fas
                      icon="briefcase"
                      className={"mt-2"}
                      style={{ fontSize: "15px", marginLeft: "14px" }}
                    />
                  }
                  label={this.state.profile.occupation || "---"}
                  variant="outlined"
                  size="medium"
                  className={"m-1"}
                />

              </MDBCol>
              <MDBCol></MDBCol>
            </MDBRow>


            {/* about me section */}
            <div>
              <label
                className={"m-1 mt-5"}
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                {this.props.i18n.t("about_me")}
              </label>
            </div>

            <MDBRow className={"mt-4"}>
              <MDBCol
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <Chip
                  style={{ ...BoxMobile }}
                  avatar={
                    <MDBIcon
                      fas
                      icon="map-marker"
                      className={"mt-2"}
                      style={{ fontSize: "15px", marginLeft: "14px" }}
                    />
                  }
                  label={this.state.profile.country_of_live || "---"}
                  variant="outlined"
                  size="medium"
                  className={"m-1"}
                />

                <Chip
                  style={{ ...BoxMobile }}
                  avatar={
                    <GiDoorRingHandle
                      style={{ fontSize: "15px", marginRight: "10px" }}
                    />
                  }
                  label={
                    this.marital_status_lst_en.find(
                      (martial_status) =>
                        martial_status.value ===
                        this.state.profile.martial_status
                    )?.label || "---"
                  }
                  variant="outlined"
                  size="medium"
                  className={"m-1"}
                />
              </MDBCol>
            </MDBRow>

            {this.state.profile.languages && this.state.profile.languages.length > 0 && (
              <MDBRow className="mt-3">
                <MDBCol
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    flexWrap: "wrap"
                  }}
                >
                  {this.state.profile.languages.map((language, index) => (
                    <Chip
                      key={index}
                      style={{
                        width: "calc(38% - 5px)", // Ensure two columns with spacing
                        ...BoxMobile,
                      }}
                      avatar={
                        <IoLanguageOutline
                          style={{ fontSize: "15px", marginRight: "10px" }}
                        />
                      }
                      label={
                        this.languages_lst_en.find(
                          (langu) =>
                            langu.value === language.value
                        )?.label || "---"

                      }
                      // label={`${language.label}`}
                      variant="outlined"
                      size="medium"
                      className="m-1"
                    />
                  ))}
                </MDBCol>
              </MDBRow>
            )}

            <MDBRow className={"mt-3"}>
              <MDBCol
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <Chip
                  style={{ ...BoxMobile }}
                  avatar={
                    <MdHeight
                      style={{ fontSize: "15px", marginRight: "10px" }}
                    />
                  }
                  label={
                    this.state.profile.height || "---"
                  }
                  variant="outlined"
                  size="medium"
                  className={"m-1"}
                />

                <Chip
                  style={{ ...BoxMobile }}
                  avatar={
                    <MdMonitorWeight
                      style={{ fontSize: "15px", marginRight: "10px" }}
                    />
                  }
                  label={this.state.profile.weight || "---"}
                  variant="outlined"
                  size="medium"
                  className={"m-1"}
                />
              </MDBCol>
            </MDBRow>


            {/* Interest Section */}
            <div>
              <label
                className={"m-1 mt-5"}
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                {this.props.i18n.t("Interest")}
              </label>
            </div>

            <MDBRow className={"mt-3"}>
              <MDBCol
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
              </MDBCol>
            </MDBRow>

            {/* Personality section */}
            <div>
              <label
                className={"m-1 mt-5 mb-10"}
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                {this.props.i18n.t("Personality")}
              </label>
            </div>
            <MDBRow className={"mt-3"}>
              <MDBCol
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}>

              </MDBCol>
            </MDBRow>
            <MDBRow className={"mb-5 mt-5"}>
              <MDBCol
                className={
                  "text-center align-items-center justify-content-center d-flex"
                }
              >
                <button
                  className="btn"
                  style={{
                    background: "#3B3B3B",
                    color: "#fff",
                    marginTop: "20px",
                    fontSize: "20px",
                    marginBottom: "50px",
                  }}
                  onClick={(v) => this.EditBTN(v)}
                >
                  <label>{this.props.i18n.t("Edit_profile")}</label>
                </button>
              </MDBCol>
            </MDBRow>
          </MDBRow>
        </body>
      );
    }
  }

  desktopView() {
    if (this.props.i18n.language === "fa") {
      if (this.state.isIntro == null || this.state.isIntro == false) {
        return (
          <body
            className="text-right bg-body"
            dir="rtl"
            style={{ height: "100vh" }}
          >
            <div className={"container-fluid"}>
              <RightSideNavbar
                profile={this.state.profile}
                page={"my-profile"}
              />

              <IntroPage />
            </div>
          </body>
        );
      }
      return (
        <body
          className="text-right bg-body"
          dir="rtl"
          style={{ height: "100vh" }}
        >
          <div className={"container-fluid"} style={{ backgroundColor: "#F5F5F5" }}>
            <RightSideNavbar profile={this.state.profile} page={"my-profile"} />

            <div style={{ margin: "50px 300px 150px 60px", padding: "10px 10px", backgroundColor: "white", borderRadius: "50px", border: "0.5px solid black" }}>
              <MDBRow className={"m-0"}>
                <div className={"text-center"} style={{ position: "relative", display: "inline-block" }}>

                  <img
                    height={"270px"}
                    style={{
                      borderRadius: "5%",
                      marginTop: "90px",
                      marginBottom: "20px",
                    }}
                    src={this.state.profile.picture}
                    alt="Profile"
                  />

                  {/* Display the verified badge if verified is true */}
                  {this.state.profile.is_verified && (
                    <img
                      src="/verified.png"  // Path to the image in the public folder
                      alt="Verified"
                      style={{
                        position: "relative",
                        top: "-95px",
                        left: "25px",
                        width: "45px",
                        height: "45px",
                        borderRadius: "50%",
                        WebkitTransform: 180,
                      }}></img>)}

                  <div style={{ direction: "rtl", fontSize: "1.3em", fontWeight: "bold" }}>{this.state.profile.username} / 29{this.state.profile.birthday}</div>
                </div>
              </MDBRow>

              {/* Bio section */}

              <MDBRow className={"p-0 m-0 text-right"}>
                <MDBCol>
                  <MDBRow>
                    <MDBCol>
                      <label style={{ fontWeight: "bold" }}>
                        {this.props.i18n.t("Bio")}
                      </label>
                      <div> {this.state.profile.about_me} </div>
                    </MDBCol>
                  </MDBRow>

                  <div>
                    <label
                      className={"m-1 mt-5"}
                      style={{
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      {this.props.i18n.t("nationally_and_region")}
                    </label>
                  </div>

                  <MDBRow className={"mt-4"}>
                    <MDBCol
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >

                      <Chip
                        style={{ minWidth: "125px", ...Box }}
                        avatar={
                          <MDBIcon flag={this.state.profile.nationality.flag} className={'m-2'} />
                        }
                        label={
                          this.nationality_tpl.find(
                            (nationality) =>
                              nationality.value === this.state.profile.nationality.name
                          )?.label || "---"}
                        variant="outlined"
                        size="medium"
                        className={"m-1"}
                      />
                      <Chip
                        style={{ minWidth: "125px", ...Box }}
                        avatar={
                          <MDBIcon
                            fas
                            icon=""
                            className={"mt-2"}
                            style={{ fontSize: "15px", marginLeft: "14px" }}
                          />
                        }
                        label={
                          this.religious_lst.find(
                            (religious) =>
                              religious.value === this.state.profile.religious
                          )?.label || "---"}
                        variant="outlined"
                        size="medium"
                        className={"m-1"}
                      />

                      {this.state.profile.religious_type != 0 && (
                        <Chip
                          style={{ minWidth: "125px", ...Box }}
                          avatar={
                            <MDBIcon
                              fas
                              icon=""
                              className={"mt-2"}
                              style={{ fontSize: "15px", marginLeft: "14px" }}
                            />
                          }
                          label={
                            this.religious_type_lst.find(
                              (religious) =>
                                religious.value === this.state.profile.religious_type
                            )?.label}
                          variant="outlined"
                          size="medium"
                          className={"m-1"}
                        />
                      )}


                    </MDBCol>
                  </MDBRow>
                  {/* education_and_career */}
                  <div>
                    <label
                      className={"m-1 mt-5"}
                      style={{
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      {this.props.i18n.t("education_and_career")}
                    </label>
                  </div>
                  <MDBRow className={"mt-3"}>
                    <MDBCol
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <Chip
                        style={{ minWidth: "125px", ...Box }}
                        avatar={
                          <MDBIcon
                            fas
                            icon="user-graduate"
                            className={"mt-2"}
                            style={{ fontSize: "15px", marginLeft: "14px" }}
                          />
                        }
                        label={
                          this.degree_lst.find(
                            (degree) =>
                              degree.value === this.state.profile.degree
                          )?.label || "---"
                        }
                        variant="outlined"
                        size="medium"
                        className={"m-1"}
                      />

                      <Chip
                        style={{ minWidth: "125px", ...Box }}
                        avatar={
                          <MDBIcon
                            fas
                            icon="book-open"
                            className={"mt-2"}
                            style={{ fontSize: "15px", marginLeft: "14px" }}
                          />
                        }
                        label={this.state.profile.major || "---"}
                        variant="outlined"
                        size="medium"
                        className={"m-1"}
                      />


                      <Chip
                        style={{ minwidth: "145px", ...Box }}
                        avatar={
                          <MDBIcon
                            fas
                            icon="briefcase"
                            className={"mt-2"}
                            style={{ fontSize: "15px", marginRight: "5px", marginLeft: "5px" }}
                          />
                        }
                        label={this.state.profile.occupation || "---"}
                        variant="outlined"
                        size="medium"
                        className={"m-1"}
                      />

                    </MDBCol>
                  </MDBRow>
                  {/* about me section */}

                  <div>
                    <label
                      className={"m-1 mt-5"}
                      style={{
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      {this.props.i18n.t("about_me")}
                    </label>
                  </div>


                  <MDBRow className={"mt-3"}>
                    <MDBCol
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <Chip
                        style={{ minWidth: "125px", ...Box }}
                        avatar={
                          <MDBIcon
                            fas
                            icon="map-marker"
                            className={"mt-2"}
                            style={{ fontSize: "15px", marginLeft: "14px" }}
                          />
                        }
                        label={this.state.profile.country_of_live || "---"}
                        variant="outlined"
                        size="medium"
                        className={"m-1"}
                      />

                      <Chip
                        style={{ minWidth: "125px", ...Box }}
                        avatar={
                          <GiDoorRingHandle
                            style={{ fontSize: "15px", marginRight: "10px" }}
                          />
                        }
                        label={
                          this.marital_status_lst_en.find(
                            (martial_status) =>
                              martial_status.value ===
                              this.state.profile.martial_status
                          )?.label || "---"
                        }
                        variant="outlined"
                        size="medium"
                        className={"m-1"}
                      />



                      {this.state.profile.languages && this.state.profile.languages.length > 0 && (

                        this.state.profile.languages.map((language, index) => (
                          <Chip
                            key={index}
                            style={{
                              width: "150px", // Ensure two columns with spacing
                              ...Box,
                            }}
                            avatar={
                              <IoLanguageOutline
                                style={{ fontSize: "15px", marginRight: "10px" }}
                              />
                            }
                            label={
                              this.languages_lst_fa.find(
                                (langu) =>
                                  langu.value === language.value
                              )?.label

                            }
                            // label={`${language.label}`}
                            variant="outlined"
                            size="medium"
                            className="m-1"
                          />
                        ))

                      )}

                      <Chip
                        style={{ minWidth: "125px", ...Box }}
                        avatar={
                          <MdHeight
                            style={{ fontSize: "15px", marginRight: "10px" }}
                          />
                        }
                        label={this.state.profile.height || "---"}
                        variant="outlined"
                        size="medium"
                        className={"m-1"}
                      />

                      <Chip
                        style={{ minWidth: "125px", ...Box }}
                        avatar={
                          <MdMonitorWeight
                            style={{ fontSize: "15px", marginRight: "10px" }}
                          />
                        }
                        label={this.state.profile.weight || "---"}
                        variant="outlined"
                        size="medium"
                        className={"m-1"}
                      />
                    </MDBCol>
                  </MDBRow>


                  {/* Interest Section */}
                  <div>
                    <label
                      className={"m-1 mt-5"}
                      style={{
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      {this.props.i18n.t("Interest")}
                    </label>
                  </div>

                  <MDBRow className={"mt-3"}>
                    <MDBCol
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                    </MDBCol>
                  </MDBRow>
                  {/* Personality section */}
                  <div>
                    <label
                      className={"m-1 mt-5"}
                      style={{
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      {this.props.i18n.t("Personality")}
                    </label>
                  </div>
                  <MDBRow className={"mt-3"}>
                    <MDBCol
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >

                    </MDBCol>
                  </MDBRow>

                  <MDBRow className={"mb-5 mt-5"}>
                    <MDBCol
                      className={
                        "text-center align-items-center justify-content-center d-flex"
                      }
                    >
                      <button
                        className="btn"
                        style={{
                          background: "#3B3B3B",
                          color: "#fff",
                          marginTop: "20px",
                          fontSize: "20px",
                        }}
                        onClick={(v) => this.EditBTN(v)}
                      >
                        <label>{this.props.i18n.t("Edit_profile")}</label>
                      </button>
                    </MDBCol>
                  </MDBRow>
                </MDBCol>


              </MDBRow>
            </div>
          </div>
        </body>
      );
    } else {
      if (this.state.isIntro == null || this.state.isIntro == false) {
        return (
          <body
            className="text-left bg-body ltr"
            style={{ height: "100vh", backgroundColor: "#F5F5F5" }}
          >
            <div className={"container-fluid"}>
              <RightSideNavbar
                profile={this.state.profile}
                page={"my-profile"}
              />
              <div
                style={{
                  marginLeft: "250px",
                  padding: "0px 10px",
                  backgroundColor: "#355070",
                }}
              >
                <IntroPage />
              </div>
            </div>
          </body>
        );
      }

      return (
        <body
          className="text-left ltr bg-body"
          style={{ height: "100vh" }}
        >
          <div className={"container-fluid"} style={{ backgroundColor: "#F5F5F5", position: "absolute" }}>
            <RightSideNavbar profile={this.state.profile} page={"my-profile"} />

            <div style={{ margin: "50px 60px 50px 300px", padding: "10px 10px", backgroundColor: "white", borderRadius: "50px", border: "0.5px solid black" }}>
              <MDBRow className={"m-0"}>
                <div className={"text-center"} style={{ position: "relative", display: "inline-block" }}>

                  <img
                    height={"270px"}
                    style={{
                      borderRadius: "5%",
                      marginTop: "90px",
                      marginBottom: "20px",
                    }}
                    src={this.state.profile.picture}
                    alt="Profile"
                  />

                  {/* Display the verified badge if verified is true */}
                  {this.state.profile.is_verified && (
                    <img
                      src="/verified.png"  // Path to the image in the public folder
                      alt="Verified"
                      style={{
                        position: "relative",
                        top: "-95px",
                        right: "25px",
                        width: "45px",
                        height: "45px",
                        borderRadius: "50%",
                      }}></img>)}

                  <div style={{ direction: "ltr", fontSize: "1.3em", fontWeight: "bold" }}>{this.state.profile.username} / 29{this.state.profile.birthday}</div>
                </div>
              </MDBRow>
              {/* Bio section */}
              <MDBRow className={"p-0 m-2 text-left"}>
                <MDBCol>
                  <MDBRow>
                    <MDBCol>
                      <label style={{ fontWeight: "bold" }}>
                        {this.props.i18n.t("Bio")}
                      </label>
                      <div> {this.state.profile.about_me} </div>
                    </MDBCol>
                  </MDBRow>

                  <div>
                    <label
                      className={"m-1 mt-5"}
                      style={{
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      {this.props.i18n.t("nationally_and_region")}
                    </label>
                  </div>

                  <MDBRow className={"mt-4"}>
                    <MDBCol
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >

                      <Chip
                        style={{ minWidth: "125px", ...Box }}
                        avatar={
                          <MDBIcon flag={this.state.profile.nationality.flag} className={'m-2'} />
                        }
                        label={
                          this.nationality_tpl_en.find(
                            (nationality) =>
                              nationality.value === this.state.profile.nationality.name
                          )?.label || "---"}
                        variant="outlined"
                        size="medium"
                        className={"m-1"}
                      />

                      <Chip
                        style={{ minWidth: "125px", ...Box }}
                        avatar={
                          <MDBIcon
                            fas
                            icon=""
                            className={"mt-2"}
                            style={{ fontSize: "15px", marginLeft: "14px" }}
                          />
                        }
                        label={
                          this.religious_lst_en.find(
                            (religious) =>
                              religious.value === this.state.profile.religious
                          )?.label || "---"}
                        variant="outlined"
                        size="medium"
                        className={"m-1"}
                      />

                      {this.state.profile.religious_type != 0 && (
                        <Chip
                          style={{ minWidth: "125px", ...Box }}
                          avatar={
                            <MDBIcon
                              fas
                              icon=""
                              className={"mt-2"}
                              style={{ fontSize: "15px", marginLeft: "14px" }}
                            />
                          }
                          label={
                            this.religious_type_lst_en.find(
                              (religious) =>
                                religious.value === this.state.profile.religious_type
                            )?.label}
                          variant="outlined"
                          size="medium"
                          className={"m-1"}
                        />
                      )}


                    </MDBCol>
                  </MDBRow>
                  {/* education_and_career */}
                  <div>
                    <label
                      className={"m-1 mt-5"}
                      style={{
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      {this.props.i18n.t("education_and_career")}
                    </label>
                  </div>

                  <MDBRow className={"mt-4"}>
                    <MDBCol
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <Chip
                        style={{ minWidth: "125px", ...Box }}
                        avatar={
                          <MDBIcon
                            fas
                            icon="user-graduate"
                            className={"mt-2"}
                            style={{ fontSize: "15px", marginLeft: "14px" }}
                          />
                        }
                        label={
                          this.degree_lst_en.find(
                            (degree) =>
                              degree.value === this.state.profile.degree
                          )?.label || "---"
                        }
                        variant="outlined"
                        size="medium"
                        className={"m-1"}
                      />

                      <Chip
                        style={{ minWidth: "125px", ...Box }}
                        avatar={
                          <MDBIcon
                            fas
                            icon="book-open"
                            className={"mt-2"}
                            style={{ fontSize: "15px", marginLeft: "14px" }}
                          />
                        }
                        label={this.state.profile.major || "---"}
                        variant="outlined"
                        size="medium"
                        className={"m-1"}
                      />

                      <Chip
                        style={{ minwidth: "125px", ...Box }}
                        avatar={
                          <MDBIcon
                            fas
                            icon="briefcase"
                            className={"mt-2"}
                            style={{ fontSize: "15px", marginRight: "-5px", marginLeft: "5px" }}
                          />
                        }
                        label={this.state.profile.occupation || "---"}
                        variant="outlined"
                        size="medium"
                        className={"m-1"}
                      />

                    </MDBCol>
                  </MDBRow>
                  {/* about me section */}
                  <div>
                    <label
                      className={"m-1 mt-5"}
                      style={{
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      {this.props.i18n.t("about_me")}
                    </label>
                  </div>

                  <MDBRow className={"mt-4"}>
                    <MDBCol
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <Chip
                        style={{ minWidth: "125px", ...Box }}
                        avatar={
                          <MDBIcon
                            fas
                            icon="map-marker"
                            className={"mt-2"}
                            style={{ fontSize: "15px", marginLeft: "14px" }}
                          />
                        }
                        label={this.state.profile.country_of_live || "---"}
                        variant="outlined"
                        size="medium"
                        className={"m-1"}
                      />

                      <Chip
                        style={{ minWidth: "125px", ...Box }}
                        avatar={
                          <GiDoorRingHandle
                            style={{ fontSize: "15px", marginRight: "10px" }}
                          />
                        }
                        label={
                          this.marital_status_lst_en.find(
                            (martial_status) =>
                              martial_status.value ===
                              this.state.profile.martial_status
                          )?.label || "---"
                        }
                        variant="outlined"
                        size="medium"
                        className={"m-1"}
                      />

                      {this.state.profile.languages && this.state.profile.languages.length > 0 && (

                        this.state.profile.languages.map((language, index) => (
                          <Chip
                            key={index}
                            style={{
                              width: "150px", // Ensure two columns with spacing
                              ...Box,
                            }}
                            avatar={
                              <IoLanguageOutline
                                style={{ fontSize: "15px", marginRight: "10px" }}
                              />
                            }
                            label={
                              this.languages_lst_en.find(
                                (langu) =>
                                  langu.value === language.value
                              )?.label || "---"

                            }
                            // label={`${language.label}`}
                            variant="outlined"
                            size="medium"
                            className="m-1"
                          />
                        ))

                      )}

                      <Chip
                        style={{ minWidth: "125px", ...Box }}
                        avatar={
                          <MdHeight
                            style={{ fontSize: "15px", marginRight: "10px" }}
                          />
                        }
                        label={this.state.profile.height || "---"}
                        variant="outlined"
                        size="medium"
                        className={"m-1"}
                      />

                      <Chip
                        style={{ minWidth: "125px", ...Box }}
                        avatar={
                          <MdMonitorWeight
                            style={{ fontSize: "15px", marginRight: "10px" }}
                          />
                        }
                        label={this.state.profile.weight || "---"}
                        variant="outlined"
                        size="medium"
                        className={"m-1"}
                      />
                    </MDBCol>
                  </MDBRow>

                  {/* Interest Section */}
                  <div>
                    <label
                      className={"m-1 mt-5"}
                      style={{
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      {this.props.i18n.t("Interest")}
                    </label>
                  </div>

                  <MDBRow className={"mt-4"}>
                    <MDBCol
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      {/* <Chip
                        style={{ minWidth: "125px", ...Box }}
                        avatar={
                          <MDBIcon
                            fas
                            icon="book-open"
                            className={"mt-2"}
                            style={{ fontSize: "15px", marginLeft: "14px" }}
                          />
                        }
                        label="Amir"
                        variant="outlined"
                        size="medium"
                        className={"m-1"}
                      /> */}
                    </MDBCol>
                  </MDBRow>

                  {/* Personality section */}
                  <div>
                    <label
                      className={"m-1 mt-5"}
                      style={{
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      {this.props.i18n.t("Personality")}
                    </label>
                  </div>

                  <MDBRow className={"mt-4"}>
                    <MDBCol
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className={"mb-5"}>
                    <MDBCol
                      className={
                        "text-center align-items-center justify-content-center d-flex"
                      }
                    >
                      <button
                        className="btn"
                        style={{
                          background: "#3B3B3B",
                          color: "#fff",
                          marginTop: "20px",
                          fontSize: "20px",
                        }}
                        onClick={(v) => this.EditBTN(v)}
                      >
                        <label>{this.props.i18n.t("Edit_profile")}</label>
                      </button>
                    </MDBCol>
                  </MDBRow>
                </MDBCol>


              </MDBRow>
            </div>
          </div>
        </body>
      );
    }
  }

  render() {
    if (localStorage.getItem("token") === null) {
      return (
        <div style={{ fontWeight: "bold", fontSize: "25px", direction: "ltr" }}>
          Forbidden!
        </div>
      );
    }

    if (this.state.profile !== null) {
      return (
        <div>
          <ToastContainer />
          <BrowserView>{this.desktopView()}</BrowserView>
          <MobileView>{this.mobileView()}</MobileView>
        </div>
      );
    } else {
      return <di></di>;
    }
  }

  chatBTN(st) {
    window.location.href = "/chatroom";
  }

  heightInputValue(value) {
    this.state.profile.height = parseInt(value.target.value);
  }

  show_only_widowedValue(value) {
    console.log(value.target.checked);
    this.state.profile.is_happy_to_match_with_widow =
      !this.state.profile.is_happy_to_match_with_widow;
  }

  show_only_singlesValue(value) {
    console.log(value.target.checked);
    this.state.profile.is_happy_to_match_with_single =
      !this.state.profile.is_happy_to_match_with_single;
  }

  show_only_divorcedValue(value) {
    this.state.profile.is_happy_to_match_with_divorced =
      !this.state.profile.is_happy_to_match_with_divorced;
    console.log(this.state.profile.is_happy_to_match_with_divorced);
  }

  weightInputValue(value) {
    console.log(value.target.value);
    this.state.profile.weight = parseInt(value.target.value);
  }

  cityInputValue(value) {
    this.state.profile.city_of_live = value.target.value;
  }

  countryInputValue(value) {
    this.state.profile.country_of_live = value.target.value;
  }

  occupationInputValue(value) {
    this.state.profile.occupation = value.target.value;
  }

  marital_statusValue(value) {
    this.state.profile.martial_status = parseInt(value["value"]);
  }

  languageValue(value) {
    if (value !== undefined && value.length > 0) {
      this.state.profile.languages = value;
    }
  }

  number_of_childrenValue(value) {
    this.state.profile.number_of_children = parseInt(value.target.value);
  }

  min_ageValue(value) {
    this.state.profile.min_age_for_match = parseInt(value.target.value);
  }

  max_ageValue(value) {
    this.state.profile.max_age_for_match = parseInt(value.target.value);
  }

  min_heightValue(value) {
    this.state.profile.min_height_for_match = parseInt(value.target.value);
  }

  max_heightValue(value) {
    this.state.profile.max_height_for_match = parseInt(value.target.value);
  }

  min_weightValue(value) {
    this.state.profile.min_weight_for_match = parseInt(value.target.value);
  }

  max_weightValue(value) {
    this.state.profile.max_weight_for_match = parseInt(value.target.value);
  }

  min_educationValue(value) {
    this.state.profile.min_education_for_match = value["value"];
  }

  max_educationValue(value) {
    this.state.profile.max_education_for_match = value["value"];
  }

  filterValue(value) {
    if (value !== undefined && value.length > 0) {
      this.state.profile.filters = value;
    }
  }

  majorValue(value) {
    this.state.profile.major = value.target.value;
  }

  about_me_Value(value) {
    if (this.isEnglish(value.target.value)) {
      this.state.profile.about_me = value.target.value;
    } else {
      toast.error(this.props.i18n.t("data_should_be_in_english"), {
        position: toast.POSITION.TOP_LEFT,
      });
      value.target.value = "";
    }
  }

  degreeValue(value) {
    this.state.profile.degree = value["value"];
  }

  EditBTN(st) {
    window.location.href = "/edit-profile";
  }
  handleUpload(v) {
    if (
      this.state.selectedFile === null ||
      this.state.selectedFile === undefined
    ) {
      toast.info(this.props.i18n.t("profile_update_image_error_toast"), {
        position: toast.POSITION.TOP_LEFT,
      });
      return false;
    }

    const formData = new FormData();
    formData.append("image", this.state.selectedFile);

    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: formData,
    };

    fetch(
      "/api/v1/upload-profile-image/",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 202) {
          this.setState({
            profile: data.data,
            selectedFile: null,
          });
        } else {
          toast.error(this.props.i18n.t("image_avatar_res_error"), {
            position: toast.POSITION.TOP_LEFT,
          });
        }
      })
      .catch((error) => {
        toast.error(this.props.i18n.t("image_avatar_update_error_toast"), {
          position: toast.POSITION.TOP_LEFT,
        });
      });
  }

  birthdayValue(value) {
    this.state.profile.birthday = value.target.value;
  }

  handleFileChange(e) {
    this.setState({ selectedFile: e.target.files[0] });
  }

  stringIsEnglish(str) {
    var ENGLISH = {};
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
      .split("")
      .forEach(function (ch) {
        ENGLISH[ch] = true;
      });
    var index;

    for (index = str.length - 1; index >= 0; --index) {
      if (!ENGLISH[str.substring(index, index + 1)]) {
        return false;
      }
    }
    return true;
  }

  isEnglish(str) {
    // var check = /^[a-zA-Z_0-9\u00C0-\u017F][a-zA-Z_0-9\u00C0-\u017F\s]*$/g.test(str);
    // var check = /^[a-zA-Z0-9\s,.\/]+$/g.test(str);
    // return check;
    return true;
  }
}

function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />;
}

export default connect()(withParams(withTranslation()(UserProfile)));
