import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import React, {Component} from 'react';
import {
    MDBCollapse,
    MDBContainer,
    MDBIcon,
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarItem,
    MDBNavbarLink,
    MDBNavbarNav,
    MDBNavbarToggler,
} from 'mdb-react-ui-kit';
import {connect} from "react-redux";
import {useNavigate, useParams} from "react-router";
import {FaSignInAlt, FaUser} from "react-icons/fa";
import {BrowserView, MobileView} from "react-device-detect";
import {Button} from "@mui/material";
import { withTranslation } from 'react-i18next';
import {LanguageContext} from "../LanguageContext";


class Nav extends Component{

    static contextType = LanguageContext;

    constructor(props) {
        super(props);

        this.state = {
            open: false,
            page_name: props.page

        }


    }

    componentDidMount() {
        this.chooseLang();

    }

    chooseLang() {
        var l = localStorage.getItem("lang")
        if (l !== null) {
            const {setLanguage} = this.context;
            setLanguage(l)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.chooseLang();
    }

    desktopView(){
        return (
            <MDBNavbar expand='lg' light bgColor='light' className={'p-3'} style={{direction: 'ltr'}}>
                <MDBContainer fluid>
                    <MDBNavbarBrand href='/'>
                        <img
                            src='/logo192.png'
                            height='40'
                            alt='کانون هدایت'
                            loading='lazy'
                        />
                    </MDBNavbarBrand>

                    <MDBNavbarToggler
                        aria-controls='navbarSupportedContent'
                        aria-expanded='false'
                        aria-label='Toggle navigation'
                        onClick={() => this.setState({open: !this.state.open})}
                    >
                        <MDBIcon icon='bars' fas />
                    </MDBNavbarToggler>

                    <MDBCollapse navbar center show={this.state.open}>

                        {this.getItems(false)}


                    </MDBCollapse>

                    {this.getButton(false)}
                </MDBContainer>
            </MDBNavbar>
        );
    }

    mobileView(){
        if(this.props.i18n.language === 'fa'){
            return (
                <MDBNavbar expand='lg' light bgColor='light' className={'p-3'} style={{direction: 'ltr'}}>
                    <MDBContainer fluid>
                        <MDBNavbarBrand href='/'>
                            <img
                                src='/logo192.png'
                                height='20'
                                alt='کانون هدایت'
                                loading='lazy'
                            />
                        </MDBNavbarBrand>
                        <MDBNavbarToggler
                            aria-controls='navbarSupportedContent'
                            aria-expanded='false'
                            aria-label='Toggle navigation'
                            onClick={() => this.setState({open: !this.state.open})}
                        >
                            <MDBIcon icon='bars' fas />
                        </MDBNavbarToggler>

                        <MDBCollapse navbar center show={this.state.open}>

                            {this.getItems(true)}


                        </MDBCollapse>

                    </MDBContainer>
                </MDBNavbar>
            );
        }else {
            return (
                <MDBNavbar expand='lg' light bgColor='light' className={'p-3 text-start'} style={{direction: 'ltr'}}>
                    <MDBContainer fluid>
                        <MDBNavbarBrand href='/'>
                            <img
                                src='/logo192.png'
                                height='20'
                                alt='کانون هدایت'
                                loading='lazy'
                            />
                        </MDBNavbarBrand>
                        <MDBNavbarToggler
                            aria-controls='navbarSupportedContent'
                            aria-expanded='false'
                            aria-label='Toggle navigation'
                            onClick={() => this.setState({open: !this.state.open})}
                        >
                            <MDBIcon icon='bars' fas />
                        </MDBNavbarToggler>

                        <MDBCollapse navbar center show={this.state.open}>

                            {this.getItems(true)}


                        </MDBCollapse>

                    </MDBContainer>
                </MDBNavbar>
            );
        }

    }

    render() {
        return(
            <div>
                <BrowserView>
                    {this.desktopView()}
                </BrowserView>
                <MobileView>
                    {this.mobileView()}
                </MobileView>
            </div>
        )
    }



    getButton(isMobile){
        const { setLanguage } = this.context;

        const changeLangBTN = (newLanguage) => {
            localStorage.setItem("lang", newLanguage);
            setLanguage(newLanguage);
        };

        if(isMobile){
            if(this.props.user_profile !== undefined &&
                this.props.user_profile !== null &&
                localStorage.getItem("token") !== null &&
                this.state.page_name !== 'logout' &&
                this.props.user_profile.is_registration_complete !== undefined &&
                this.props.user_profile.is_verified !== undefined
            ){
                return(
                    <div>

                        <Button
                            className={'p-2'}
                            onClick={(v) => this.gotoProfile(v)}
                            variant="contained"
                            sx={{
                                border: 1,
                                boxShadow: 0,
                                backgroundColor: "#FFF",
                                fontFamily: 'IRANSansX',
                                fontSize: "10px",
                                color: "#848484",
                                borderRadius: '5px',
                                ":hover": {
                                    bgcolor: "#BEE3DB",
                                    color: "#fff",
                                    boxShadow: 0,
                                }
                            }}
                        >
                            <label style={{fontFamily: 'IRANSansX'}}> <FaUser/> {this.props.t("profile_btn")} </label>
                        </Button>

                        <div className={'mt-3'}>
                            <MDBIcon flag='uk' style={{cursor: 'pointer'}} onClick={(e) => changeLangBTN('en')}/>
                            <MDBIcon flag='ir' style={{cursor: 'pointer'}} onClick={(e) => changeLangBTN('fa')}/>
                        </div>

                    </div>
                )
            }else {
                return (
                    <div>
                        <a href='/authentication' className='text-decoration-none'>
                            <button className="btn" style={{background: '#FF686B', color: '#fff'}} >
                                <label style={{fontFamily: 'IRANSansX'}}><FaSignInAlt/> {this.props.t("profile_register_login_btn")}</label>
                            </button>
                        </a>

                        <div className={'mt-3'}>
                            <MDBIcon flag='uk' style={{cursor: 'pointer'}} onClick={(e) => changeLangBTN('en')}/>
                            <MDBIcon flag='ir' style={{cursor: 'pointer'}} onClick={(e) => changeLangBTN('fa')}/>
                        </div>

                    </div>
                )
            }
        }
        if(this.props.user_profile !== undefined &&
            this.props.user_profile !== null &&
            localStorage.getItem("token") !== null &&
            this.state.page_name !== 'logout' &&
            this.props.user_profile.is_registration_complete !== undefined &&
            this.props.user_profile.is_verified !== undefined
        ){
            return(
                <div>

                    <Button
                        className={'p-2'}
                        onClick={(v) => this.gotoProfile(v)}
                        variant="contained"
                        sx={{
                            border: 1,
                            boxShadow: 0,
                            backgroundColor: "#FFF",
                            fontSize: "10px",
                           fontFamily: 'IRANSansX',
                            color: "#848484",
                            borderRadius: '5px',
                            ":hover": {
                                bgcolor: "#BEE3DB",
                                color: "#fff",
                                boxShadow: 0,
                            }
                        }}
                    >
                        <label style={{fontFamily: 'IRANSansX'}}> <FaUser/> {this.props.t("profile_btn")} </label>
                    </Button>


                    <MDBIcon flag='uk' style={{marginLeft: '10px', cursor: 'pointer'}} onClick={(e) => changeLangBTN('en')}/>
                    <MDBIcon flag='ir' style={{cursor: 'pointer'}} onClick={(e) => changeLangBTN('fa')}/>


                </div>
            )
        }else {
            return (
                <div>
                    <a href='/authentication' className='text-decoration-none'>
                        <button className="btn" style={{background: '#FF686B', fontFamily: 'IRANSansX', color: '#fff'}} >
                            <label><FaSignInAlt/> {this.props.t("profile_register_login_btn")}</label>
                        </button>
                    </a>

                    <MDBIcon flag='uk' style={{marginLeft: '10px', cursor: 'pointer'}} onClick={(e) => changeLangBTN('en')}/>
                    <MDBIcon flag='ir' style={{cursor: 'pointer'}} onClick={(e) => changeLangBTN('fa')}/>

                </div>
            )
        }
    }

    gotoProfile(v){
        // window.location.href = '/my-profile'
        window.location.href = '/my-profile'
    }

    getItems(isMobile) {

        if (isMobile) {
            return (
                <MDBNavbarNav className='mr-auto mb-2 mb-lg-0 justify-content-center'>

                    <MDBNavbarItem>
                        <MDBNavbarLink aria-current='page' href='/' style={{fontWeight: 'bold'}}>
                            {this.props.t("home")}
                        </MDBNavbarLink>
                    </MDBNavbarItem>

                    <MDBNavbarItem>
                        <MDBNavbarLink href='/about-us' style={{fontWeight: 'bold'}}>{this.props.t("aboutus")}</MDBNavbarLink>
                    </MDBNavbarItem>

                    <MDBNavbarItem>
                        <MDBNavbarLink href='/contact-us' style={{fontWeight: 'bold'}}>{this.props.t("contactus")}</MDBNavbarLink>
                    </MDBNavbarItem>

                    <MDBNavbarItem>
                        {this.getButton(isMobile)}
                    </MDBNavbarItem>

                </MDBNavbarNav>
            );

        } else {
            return (
                <MDBNavbarNav className='mr-auto mb-2 mb-lg-0 justify-content-center'>

                    <MDBNavbarItem>
                        <MDBNavbarLink href='/contact-us' style={{fontWeight: 'bold', marginRight: '25px'}}>{this.props.t("contact_us_footer_title")}</MDBNavbarLink>
                    </MDBNavbarItem>

                    <MDBNavbarItem>
                        <MDBNavbarLink href='/about-us' style={{fontWeight: 'bold', marginRight: '25px'}}>{this.props.t("aboutus")}</MDBNavbarLink>
                    </MDBNavbarItem>

                    <MDBNavbarItem>
                        <MDBNavbarLink aria-current='page' href='/' style={{fontWeight: 'bold'}}>
                            {this.props.t("home")}
                        </MDBNavbarLink>
                    </MDBNavbarItem>

                </MDBNavbarNav>
            );
        }
    }
}

function  mapState(state){
    return {
        user_profile: state.user_profile
    }
}
function withParams(Component) {
    return props => <Component {...props} params={useParams()} navigate={useNavigate()} />;
}

export default connect(mapState)(withParams(withTranslation()(Nav)));
