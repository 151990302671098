import React, {Component} from "react";
import {MDBCard, MDBRow} from "mdbreact";
import {Menu, menuClasses, MenuItem, Sidebar} from "react-pro-sidebar";
import {Link} from "react-router-dom";
import {FaSignOutAlt} from "react-icons/fa";
import {withTranslation} from "react-i18next";
import {LanguageContext} from "../LanguageContext";
import {    MDBIcon  } from "mdb-react-ui-kit";
import { FaMessage, FaRegCircleUser } from "react-icons/fa6";
import { PiCirclesFourLight, PiChatTeardropTextLight } from "react-icons/pi";
import { RiHomeLine } from "react-icons/ri";

class RightSideNavbar extends Component{

    static contextType = LanguageContext;

    constructor(props) {
        super(props);
        this.state = {
            profile: this.props.profile
        }
    }

    chooseLang() {
        var l = localStorage.getItem("lang")
        this.languageChangeListener = () =>{
            var lang = localStorage.getItem("lang")

        }

        this.props.i18n.on('languageChanged', this.languageChangeListener);

        if (l !== null) {
            const {setLanguage} = this.context;
            setLanguage(l)

            this.props.i18n.changeLanguage(l);
        }
    }

    didComponentMount() {
        this.chooseLang()
    }

    render() {
        if(this.props.i18n.language=== 'en') {
            return(
                <div
                    style={{
                        display: 'flex',
                        height: '100%',
                        minHeight: '400px',
                        position: 'fixed',
                        fontFamily: 'IRANSansX',
                        zIndex: '1',
                        top: '0',
                        left: '0',
                        width: '300px',
                        overflowX: 'hidden',
                    }} className={'text-center'}>
                    <MDBCard className={'shadow-6-strong'}>
                        <Sidebar style={{height:"100vh"}} backgroundColor="#FFFFFF">
                            <Menu>
                                <div style={{marginBottom: '50px'}}>
                                    <MDBRow>
                                        <div className={"text-center"}>

                                            <img height={'100'} style={{borderRadius: '70%', marginTop: '90px', marginBottom: '20px'}} src={this.state.profile.picture}/>
                                        </div>
                                    </MDBRow>
                                    <MDBRow>
                                        <div className={"text-center"} style={{fontWeight:"bold"}}>
                                            {"Hello " }{this.state.profile.first_name} {"!"}
                                        </div>
                                    </MDBRow>
                                </div>

                                {this.getMenuItems()}

                            </Menu>

                        </Sidebar >
                    </MDBCard>
                </div>
            )
        }
        return(
            <div
                style={{
                    display: 'flex',
                    height: '100%',
                    minHeight: '400px',
                    position: 'fixed',
                    zIndex: '1',
                    top: '0',
                    fontFamily: 'IRANSansX',
                    right: '0',
                    width: '300px',
                    overflowX: 'hidden',
                }} className={'text-center'}>
                <MDBCard className={'shadow-6-strong'}>
                    <Sidebar style={{height:"100vh"}} backgroundColor="#FFFFFF">
                        <Menu>
                            <div style={{marginBottom: '50px'}}>
                                <MDBRow>
                                    <div className={"text-center"}>

                                        <img height={'100'} style={{borderRadius: '70%', marginTop: '90px', marginBottom: '20px'}} src={this.state.profile.picture}/>
                                    </div>
                                </MDBRow>
                                <MDBRow>
                                    <div className={"text-center"} style={{fontFamily: 'IRANSansX',fontWeight:"bold"}}>
                                    {"سلام " }{this.state.profile.first_name} {"!"}
                                    </div>
                                </MDBRow>
                            </div>

                            {this.getMenuItems()}

                        </Menu>

                    </Sidebar >
                </MDBCard>
            </div>
        )
    }


    getMenuItems() {
        if(this.props.i18n.language=== 'en') {
            if (this.props.page === 'my-profile') {
                return (
                    <div style={{fontFamily: 'IRANSansX', textAlign:"left"}}>
                        <MenuItem rootStyles={{
                            ['.' + menuClasses.button]: {
                                backgroundColor: '#BEE3DB',
                                color: '#BEE3DB',
                                
                                '&:hover': {
                                    backgroundColor: '#BEE3DB',
                                },
                            },
                        }} component={<Link to="/my-profile"/>}> <FaRegCircleUser style={{fontSize:"1.4em", marginBottom:"5px"}} />  <span style={{ fontSize: "1.2em", fontWeight:"bold" }}>{this.props.i18n.t('my_profile')}</span> </MenuItem>
                        <MenuItem component={<Link to={"/learning"}/>}> <RiHomeLine style={{fontSize:"1.4em", marginBottom:"5px"}} /> <span style={{ fontSize: "1.2em", fontWeight:"bold" }}>{this.props.i18n.t('plans')}</span>  </MenuItem>
                        <MenuItem component={<Link to={"/users/"}/>}> <PiCirclesFourLight style={{fontSize:"1.4em", marginBottom:"5px"}} />  <span style={{ fontSize: "1.2em", fontWeight:"bold" }}>{this.props.i18n.t('encounters')}</span>  </MenuItem>
                        <MenuItem component={<Link to={"/chatroom/"}/>}> <PiChatTeardropTextLight style={{fontSize:"1.4em", marginBottom:"5px"}} /> <span style={{ fontSize: "1.2em", fontWeight:"bold" }}>{this.props.i18n.t('chatroom')}</span>  </MenuItem>

                        <div className={'mt-lg-5'}></div> 
                        <MenuItem  style={{fontSize:"1.2em", fontWeight:"bold", textAlign:"center"}} component={<Link to="/"/>}>{this.props.i18n.t('home_page')}</MenuItem>
                        <MenuItem  style={{fontSize:"1.2em", fontWeight:"bold", textAlign:"center"}} component={<Link to="/logout"/>}>{this.props.i18n.t('sign_out')}<FaSignOutAlt style={{marginLeft: '10px'}}/></MenuItem>
                    </div>
                )
            }
        


            if (this.props.page === 'learning') {
                return (
                    <div style={{fontFamily: 'IRANSansX', textAlign:"left"}}>
                        <MenuItem component={<Link to="/my-profile"/>}> <FaRegCircleUser style={{fontSize:"1.4em", marginBottom:"5px"}} />   <span style={{ fontSize: "1.2em", fontWeight:"bold" }}>{this.props.i18n.t('my_profile')}</span> </MenuItem>
                       
                        <MenuItem rootStyles={{
                            ['.' + menuClasses.button]: {
                                backgroundColor: '#BEE3DB',
                                color: '#BEE3DB',
                                '&:hover': {
                                    backgroundColor: '#BEE3DB',
                                },
                            },
                        }} component={<Link to="/learning"/>}> <RiHomeLine style={{fontSize:"1.4em", marginBottom:"5px"}} />   <span style={{ fontSize: "1.2em", fontWeight:"bold" }}>{this.props.i18n.t('plans')}</span> </MenuItem>
                        <MenuItem component={<Link to={"/users/"}/>}> <PiCirclesFourLight style={{fontSize:"1.4em", marginBottom:"5px"}} />  <span style={{ fontSize: "1.2em", fontWeight:"bold" }}>{this.props.i18n.t('encounters')}</span>  </MenuItem>
                        <MenuItem component={<Link to={"/chatroom/"}/>}> <PiChatTeardropTextLight style={{fontSize:"1.4em", marginBottom:"5px"}} /> <span style={{ fontSize: "1.2em", fontWeight:"bold" }}>{this.props.i18n.t('chatroom')}</span>  </MenuItem>

                        <div className={'mt-lg-5'}></div>

                        <MenuItem  style={{fontSize:"1.2em", fontWeight:"bold", textAlign:"center"}} component={<Link to="/"/>}>{this.props.i18n.t('home_page')}</MenuItem>
                        <MenuItem  style={{fontSize:"1.2em", fontWeight:"bold", textAlign:"center"}} component={<Link to="/logout"/>}>{this.props.i18n.t('sign_out')}<FaSignOutAlt style={{marginLeft: '10px'}}/></MenuItem>
                    </div>
                )
            }else {
                if (this.props.page === 'candidate-list') {
                    return (
                        <div style={{fontFamily: 'IRANSansX',textAlign:"left"}}>
                        <MenuItem component={<Link to="/my-profile"/>}> <FaRegCircleUser style={{fontSize:"1.4em", marginBottom:"5px"}} />   <span style={{ fontSize: "1.2em", fontWeight:"bold" }}>{this.props.i18n.t('my_profile')}</span> </MenuItem>
                        <MenuItem component={<Link to={"/learning"}/>}> <RiHomeLine style={{fontSize:"1.4em", marginBottom:"5px"}} />  <span style={{ fontSize: "1.2em", fontWeight:"bold" }}>{this.props.i18n.t('plans')}</span>  </MenuItem>
                        <MenuItem rootStyles={{
                            ['.' + menuClasses.button]: {
                                backgroundColor: '#BEE3DB',
                                color: '#BEE3DB',
                                '&:hover': {
                                    backgroundColor: '#BEE3DB',
                                },
                            },
                        }} component={<Link to={"/users/"}/>}> <PiCirclesFourLight style={{fontSize:"1.4em", marginBottom:"5px"}} /><span style={{ fontSize: "1.2em", fontWeight:"bold" }}>{this.props.i18n.t('encounters')}</span>  </MenuItem>
                        <MenuItem component={<Link to={"/chatroom/"}/>}> <PiChatTeardropTextLight style={{fontSize:"1.4em", marginBottom:"5px"}} /> <span style={{ fontSize: "1.2em", fontWeight:"bold" }}>{this.props.i18n.t('chatroom')}</span>  </MenuItem>

                            <div className={'mt-lg-5'}></div>

                            <MenuItem  style={{fontSize:"1.2em", fontWeight:"bold", textAlign:"center"}} component={<Link to="/"/>}>{this.props.i18n.t('home_page')}</MenuItem>
                            <MenuItem  style={{fontSize:"1.2em", fontWeight:"bold", textAlign:"center"}} component={<Link to="/logout"/>}>{this.props.i18n.t('sign_out')}<FaSignOutAlt style={{marginLeft: '10px'}}/></MenuItem>
                        </div>
                    )
                }
            }
        }else {
            if (this.props.page === 'my-profile') {
                return (
                    <div  style={{fontFamily: 'IRANSansX', textAlign:"right"}}>
                        <MenuItem rootStyles={{
                            ['.' + menuClasses.button]: {
                                backgroundColor: '#BEE3DB',
                                color: '#BEE3DB',
                                '&:hover': {
                                    backgroundColor: '#BEE3DB',
                                },
                            },
                        }} component={<Link to="/my-profile"/>}> <FaRegCircleUser style={{fontSize:"1.4em", marginBottom:"5px"}} />  <span style={{ fontSize: "1.2em", fontWeight:"bold" }}>{this.props.i18n.t('my_profile')}</span> </MenuItem>
                        <MenuItem component={<Link to={"/learning"}/>}> <RiHomeLine style={{fontSize:"1.4em", marginBottom:"5px"}} />  <span style={{ fontSize: "1.2em", fontWeight:"bold" }}>{this.props.i18n.t('plans')}</span>  </MenuItem>
                        <MenuItem component={<Link to={"/users/"}/>}> <PiCirclesFourLight style={{fontSize:"1.4em", marginBottom:"5px"}} />  <span style={{ fontSize: "1.2em", fontWeight:"bold" }}>{this.props.i18n.t('encounters')}</span>  </MenuItem>
                        <MenuItem component={<Link to={"/chatroom/"}/>}> <PiChatTeardropTextLight style={{fontSize:"1.4em", marginBottom:"5px"}} /> <span style={{ fontSize: "1.2em", fontWeight:"bold" }}>{this.props.i18n.t('chatroom')}</span>  </MenuItem>

                        <div className={'mt-lg-5'}></div>
  
                        <MenuItem   style={{fontSize:"1.2em", fontWeight:"bold", textAlign:"center"}} component={<Link to="/"/>}>{this.props.i18n.t('home_page')}</MenuItem>
                        <MenuItem  style={{fontSize:"1.2em", fontWeight:"bold", textAlign:"center"}} component={<Link to="/logout"/>}><FaSignOutAlt style={{marginLeft: '10px'}}/>{this.props.i18n.t('sign_out')}</MenuItem>
                    </div>
                )
            }

            if (this.props.page === 'learning') {
                return (
                    <div  style={{fontFamily: 'IRANSansX', textAlign:"right"}}>
                        <MenuItem component={<Link to="/my-profile"/>}> <FaRegCircleUser style={{fontSize:"1.4em", marginBottom:"5px"}} />  <span style={{ fontSize: "1.2em", fontWeight:"bold" }}>{this.props.i18n.t('my_profile')}</span> </MenuItem>
                        
                        <MenuItem  rootStyles={{
                            ['.' + menuClasses.button]: {
                                backgroundColor: '#BEE3DB',
                                color: '#BEE3DB',
                                '&:hover': {
                                    backgroundColor: '#BEE3DB',
                                },
                            },
                        }} component={<Link to={"/learning"}/>}> <RiHomeLine style={{fontSize:"1.4em", marginBottom:"5px"}} />  <span style={{ fontSize: "1.2em", fontWeight:"bold" }}>{this.props.i18n.t('plans')}</span>  </MenuItem>
                       <MenuItem component={<Link to={"/users/"}/>}> <PiCirclesFourLight style={{fontSize:"1.4em", marginBottom:"5px"}} />  <span style={{ fontSize: "1.2em", fontWeight:"bold" }}>{this.props.i18n.t('encounters')}</span>  </MenuItem>
                        <MenuItem component={<Link to={"/chatroom/"}/>}> <PiChatTeardropTextLight style={{fontSize:"1.4em", marginBottom:"5px"}} /> <span style={{ fontSize: "1.2em", fontWeight:"bold" }}>{this.props.i18n.t('chatroom')}</span>  </MenuItem>

                        <div className={'mt-lg-5'}></div>

                        <MenuItem   style={{fontSize:"1.2em", fontWeight:"bold", textAlign:"center"}} component={<Link to="/"/>}>{this.props.i18n.t('home_page')}</MenuItem>
                        <MenuItem  style={{fontSize:"1.2em", fontWeight:"bold", textAlign:"center"}} component={<Link to="/logout"/>}><FaSignOutAlt style={{marginLeft: '10px'}}/>{this.props.i18n.t('sign_out')}</MenuItem>
                    </div>
                )
            }else {
                if (this.props.page === 'candidate-list') {
                    return (
                        <div  style={{fontFamily: 'IRANSansX', textAlign:"right"}}>
                        <MenuItem component={<Link to="/my-profile"/>}> <FaRegCircleUser style={{fontSize:"1.4em", marginBottom:"5px"}} />  <span style={{ fontSize: "1.2em", fontWeight:"bold" }}>{this.props.i18n.t('my_profile')}</span> </MenuItem>
                        <MenuItem component={<Link to={"/learning"}/>}> <RiHomeLine style={{fontSize:"1.4em", marginBottom:"5px"}} />  <span style={{ fontSize: "1.2em", fontWeight:"bold" }}>{this.props.i18n.t('plans')}</span>  </MenuItem>
                        <MenuItem rootStyles={{
                            ['.' + menuClasses.button]: {
                                backgroundColor: '#BEE3DB',
                                color: '#BEE3DB',
                                '&:hover': {
                                    backgroundColor: '#BEE3DB',
                                },
                            },
                        }} component={<Link to={"/users/"}/>}> <PiCirclesFourLight style={{fontSize:"1.4em", marginBottom:"5px"}} /> <span style={{ fontSize: "1.2em", fontWeight:"bold" }}>{this.props.i18n.t('encounters')}</span>  </MenuItem>
                        <MenuItem component={<Link to={"/chatroom/"}/>}> <PiChatTeardropTextLight style={{fontSize:"1.4em", marginBottom:"5px"}} />   <span style={{ fontSize: "1.2em", fontWeight:"bold" }}>{this.props.i18n.t('chatroom')}</span>  </MenuItem>

                            <div className={'mt-lg-5'}></div>

                            <MenuItem  style={{fontSize:"1.2em", fontWeight:"bold", textAlign:"center"}} component={<Link to="/"/>}>{this.props.i18n.t('home_page')}</MenuItem>
                            <MenuItem  style={{fontSize:"1.2em", fontWeight:"bold", textAlign:"center"}} component={<Link to="/logout"/>}><FaSignOutAlt style={{marginLeft: '10px'}}/>{this.props.i18n.t('sign_out')}</MenuItem>
                        </div>
                    )
                }
            }
        }
    }
}
export default withTranslation()(RightSideNavbar);